import React from "react"
import Card from "@material-ui/core/Card"
import styled from "styled-components"

export default styled(Card)`
	max-width: 23.33em;
	padding: 2em;
	margin: 1.25em auto;
	box-sizing: border-box;
	@media (max-width: 600px) {
		max-width: 85%;
		margin: 1em auto;

		h1 {
			font-size: 20;
		}

		p {
			font-size: 10;
		}

		.card_image {
			max-width: 10em;
		}
	}

	@media (max-height: 600px) {
		margin: .125em auto;
	}
`